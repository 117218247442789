<template>
  <div
    :class="'member-page ' + LANGCODE"
    :style="'--aog-currency:\'' + SYSLANG.currency + '\''"
  >
    <div class="header">
      <img class="member-bg" :src="require('@/assets/images/bg-user-center.png')" />
      <div class="base-info">
        <van-image
          round
          width="20vw"
          height="20vw"
          :src="agentInfo.avatar ? agentInfo.avatar : defaultFace"
          fit="cover"
        />
        <div class="info">
          <span class="name">{{ agentInfo.realName }}</span>
          <span class="item">ID: {{ agentInfo.incId }}</span>
          <span class="item" @click="onCopy(agentInfo.inviteCode)"
            >{{ PAGELANG.invitationcode }}: {{ agentInfo.inviteCode }}</span
          >
        </div>
      </div>
    </div>

    <div class="mem-menu mem-menu-01 mem-menu-03">
      <div class="big-title">{{ PAGELANG.基本信息 }}</div>
      <div class="info">
        <div class="base-item">
          <span class="title">{{ PAGELANG.联系人 }}</span>
          <span class="value">{{ agentInfo.realName }}</span>
        </div>
        <div class="base-item">
          <span class="title">{{ PAGELANG.手机号码 }}</span>
          <span class="value">{{ agentInfo.phone }}</span>
        </div>
        <div class="base-item" style="width: 100%">
          <span class="title">{{ PAGELANG.邮箱 }}</span>
          <span class="value">{{
            agentInfo.eMail ? agentInfo.eMail : "-"
          }}</span>
        </div>
<!--        <div class="base-item">
          <span class="title">{{ PAGELANG.公司 }}</span>
          <span class="value">{{
            agentInfo.MerchantCompany ? agentInfo.MerchantCompany : "-"
          }}</span>
        </div>
        <div class="base-item">
          <span class="title">{{ PAGELANG.法人 }}</span>
          <span class="value">{{
            agentInfo.LegalPerson ? agentInfo.LegalPerson : "-"
          }}</span>
        </div> -->
        <div class="base-item">
          <span class="title">{{ PAGELANG.上级经销商 }}</span>
		  <span class="value">{{
		    agentInvite.memberNickName ? agentInvite.memberNickName : "-"
		  }}</span>
          <span class="value">{{
            agentInvite.memberPhone ? agentInvite.memberPhone : "-"
          }}</span>
        </div>
		<div class="base-item">
		  <span class="title">{{ "上上級經銷商" }}</span>
		  <span class="value">{{
		    agentInvite.inviteMemberNickName ? agentInvite.inviteMemberNickName : "-"
		  }}</span>
		  <span class="value">{{
		    agentInvite.inviteMemberPhone ? agentInvite.inviteMemberPhone : "-"
		  }}</span>
		</div>
		
        <div class="base-item">
          <span class="title">{{ PAGELANG.直属下级人数 }}</span>
          <span class="value">{{lv1count}}</span>
        </div>
		
		<div class="base-item">
		  <span class="title">{{ "間屬下級人數" }}</span>
		  <span class="value">{{lv2count}}</span>
		</div>
      </div>
    </div>
  </div>

<!--  <div class="pc-my-page">
    <MyMenu :agentInfo="agentInfo" pagename="profile" />
    <div class="main">
      <div class="profile">
        <div class="big-title">{{ PAGELANG.基本信息 }}</div>
        <div class="info">
          <div class="base-item">
            <span class="title">{{ PAGELANG.联系人 }}</span>
            <span class="value">{{ agentInfo.Contact }}</span>
          </div>
          <div class="base-item">
            <span class="title">{{ PAGELANG.手机号码 }}</span>
            <span class="value">{{ agentInfo.phone }}</span>
          </div>
          <div class="base-item" style="width: 100%">
            <span class="title">{{ PAGELANG.邮箱 }}</span>
            <span class="value">{{
              agentInfo.Email ? agentInfo.Email : "-"
            }}</span>
          </div>
          <div class="base-item">
            <span class="title">{{ PAGELANG.公司 }}</span>
            <span class="value">{{
              agentInfo.MerchantCompany ? agentInfo.MerchantCompany : "-"
            }}</span>
          </div>
          <div class="base-item">
            <span class="title">{{ PAGELANG.法人 }}</span>
            <span class="value">{{
              agentInfo.LegalPerson ? agentInfo.LegalPerson : "-"
            }}</span>
          </div>
          <div class="base-item">
            <span class="title">{{ PAGELANG.上级经销商 }}</span>
            <span class="value">{{
              agentInfo.ParentAgentName ? agentInfo.ParentAgentName : "-"
            }}</span>
          </div>
          <div class="base-item">
            <span class="title">{{ PAGELANG.直属下级人数 }}</span>
            <span class="value">{{
              agentInfo.SubAgentCount ? agentInfo.SubAgentCount : 0
            }}</span>
          </div>
          <div v-if="agentInfo.ExpireDTimeFormat" class="base-item">
            <span class="title">{{ PAGELANG.zhengshu }}</span>
            <span class="value">
              {{ agentInfo.ExpireDTimeFormat }} {{ PAGELANG.expiration }}
              <a :href="UPLOADBASE + agentInfo.CertUrl" target="_blank"
                >({{ PAGELANG.查看 }})</a
              >
            </span>
          </div>
        </div>
      </div>
    </div>
  </div> -->
</template>

<script>
import { getCookie } from "../util/index.js";
import { showLoadingToast, showToast, closeToast } from "vant";
// import MyMenu from "../components/MyMenu.vue";

export default {
  // components: {
  //   MyMenu,
  // },
  data() {
    return {
      agentInfo: {},
	  agentInvite:{},
      defaultFace: require("@/assets/images/default_face.png"),
	  lv1count:0,
	  lv2count:0
    };
  },
  beforeCreate() {
    if (!getCookie("agentlogintoken")) {
      this.$router.replace({
        path: "/login",
      });
      return;
    }
  },
  created() {
    if (!getCookie("agentlogintoken")) {
      return;
    }

    this.PAGELANG = this.LANG.pages.my;
    this.GOODSLANG = this.LANG.pages.goods;
    this.SYSLANG = this.LANG.system;
    this.LANGCODE = this.langcode;
    this.UPLOADBASE = this.uploadbase;

    this.getMemberInfo();
  },
  methods: {
    getMemberInfo() {
      showLoadingToast({
        message: this.SYSLANG.loading,
        duration: 0,
        forbidClick: true,
      });

	 this.axios.get(this.actions.memberInfo).then((response) => {
					  closeToast();
					 let { code, message, data } = response.data;
					  if (code == "200") {
					    this.agentInfo = data;
					  } else {
					    showToast({
					      message: code == "NOTLOGIN" ? this.SYSLANG[message] : this.GOODSLANG[message],
					      forbidClick: true,
					      onClose: () => {
					        if (code == "NOTLOGIN") {
					          this.$router.replace({
					            path: "/login",
					          });
					        }
					      },
					    });
					  }
					});
	this.axios.get(this.actions.memberInviteMe).then((response)=>{
		   let { code,data } = response.data;
           if(code == "200"){this.agentInvite = data;}		   
	})
	this.axios
	  .get(this.actions.memeberTeam)
	  .then((res) => {
			  if(res.data.code!=200){
				   showToast({
					    message:res.data.message
				   })
			  }else{
				  //this.agentInfo = res.data;
				  this.lv1count=res.data.data.agentBeansB.length;
				  this.lv2count=res.data.data.agentBeansC.length;
				  this.lv3count=res.data.data.agentBeansD.length;
				  closeToast();
			  }
	    //closeToast();
	  })
	  .catch((error) => {
	    console.log("views.AgentGroup.init.error", error);
	    closeToast();
	    //this.init();
	  });				

    },
    onCopy(content) {
      let oInput = document.createElement("input");
      oInput.value = content;
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象;
      console.log(oInput.value);
      document.execCommand("Copy"); // 执行浏览器复制命令
      oInput.remove();
      showToast(this.SYSLANG.copysuccess);
    },
  },
};
</script>


<style src="../assets/css/my.css" scoped>
</style>
<style scoped>
@media (max-width: 678px) {
  .mem-menu.mem-menu-03 {
    display: block;
    height: auto;
    padding-bottom: 10px;
  }
  .mem-menu.mem-menu-03 .big-title {
    display: flex;
    align-items: center;
    margin: 0 15px;
    height: 40px;
    font-size: 16px;
    font-weight: bold;
    color: #000;
  }
  .mem-menu.mem-menu-03 .big-title::before {
    content: "";
    display: block;
    width: 4px;
    height: 16px;
    background-color: var(--van-card-price-color);
    border-radius: 2px;
    margin-right: 6px;
  }

  .mem-menu.mem-menu-03 .info {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
  }

  .mem-menu.mem-menu-03 .info .base-item {
    box-sizing: border-box;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
    width: 50%;
    height: 70px;
    padding: 15px;
  }

  .mem-menu.mem-menu-03 .info .base-item .title {
    line-height: 28px;
    font-size: 14px;
    color: #555;
  }

  .mem-menu.mem-menu-03 .info .base-item .value {
    line-height: 26px;
    font-size: 16px;
    color: #000;
  }
}
@media (min-width: 679px) {
  .member-page {
    display: none;
  }
  .profile {
    padding: 30px;
  }
  .profile .big-title {
    display: flex;
    align-items: center;
    height: 40px;
    font-size: 20x;
    font-weight: bold;
    color: #000;
    margin-bottom: 10px;
  }
  .profile .big-title::before {
    content: "";
    display: block;
    width: 4px;
    height: 20px;
    background-color: var(--van-card-price-color);
    border-radius: 2px;
    margin-right: 6px;
  }
  .profile .info {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
  }
  .profile .info .base-item {
    box-sizing: border-box;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
    width: 50%;
    height: 70px;
    padding: 15px;
  }
  .profile .info .base-item .title {
    line-height: 28px;
    font-size: 14px;
    color: #555;
  }
  .profile .info .base-item .value {
    line-height: 26px;
    font-size: 16px;
    color: #000;
  }
  .profile .info .base-item .value a {
    color: #000;
  }
  .profile .info .base-item .value a:hover {
    color: var(--van-card-price-color);
  }
}
</style>